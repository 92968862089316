.css-label {
  font-family: Aller, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #516176; }

.invalid {
  border: 1px solid #FF0000 !important;
  box-shadow: none !important; }

.css-custom-select > div {
  background: rgba(196, 196, 196, 0.14) !important; }

.select-is-invalid > div {
  border-radius: 3px !important;
  border: 2px solid rgba(247, 110, 110, 0.38) !important;
  box-shadow: 0 0 0 1px rgba(247, 110, 110, 0.41);
  background: rgba(196, 196, 196, 0.14) !important; }

.select-is-valid > div {
  border-color: rgba(196, 196, 196, 0.14) !important;
  background: rgba(196, 196, 196, 0.14) !important; }

.react-datepicker-wrapper {
  width: 100%; }

.pencil-symbol:after {
  content: "\270E";
  color: white;
  font-size: 15px; }

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 1px;
  border-radius: 5px;
  border-color: #525f7f;
  border-style: dashed;
  background-color: white;
  color: #525f7f;
  outline: none;
  transition: border .24s ease-in-out;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border: 0;
  transition: box-shadow .15s ease; }
  .dropzone-files-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }
    .dropzone-files-container > div {
      border-radius: 5px; }
    .dropzone-files-container .collapse, .dropzone-files-container .collapsing {
      max-height: 59px; }
    .dropzone-files-container .collapse.show {
      min-height: 59px; }
  .dropzone.invalid {
    border-color: #fb6340;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.date-picker-component .SingleDatePickerInput, .date-picker-component .DateRangePickerInput {
  border-radius: 3px !important;
  border: 1px solid rgba(196, 196, 196, 0.14) !important;
  background: rgba(196, 196, 196, 0.14) !important;
  height: calc(1.5em + .75rem + 2px); }

.date-picker-component .DateInput {
  border-bottom: 0px solid transparent !important;
  height: 100%; }
  .date-picker-component .DateInput .DateInput_input {
    height: 100%;
    background: rgba(196, 196, 196, 0.14) !important; }
